import React from 'react';
import { Spinner } from 'reactstrap';
import { css } from 'emotion';

const spinnerStyle = css`
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-width: 0.2em !important;
`;

export const ProgressSpinner = () => <Spinner className={spinnerStyle} />;
