export const HOME = '/';
export const MY_QUOTES = '/my-quotes';
export const CLAIM_QUOTE = '/claim-quote/:id?';
export const CLAIM_POLICY = {
  PATTERN: '/claim-policy/:id?',
  URL: '/claim-policy',
};
export const MY_POLICIES = '/my-policies';
export const USER_PROFILE = '/user-profile';
export const FORGOT_PASSWORD = '/forgot-password';
