import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { InputField } from '../../shared/InputField';
import { Message } from '../../shared/Message';

import * as ROUTES from '../../../constants/routes';
import { SignInButtons } from './SignInButtons';
import { useFirebase } from '../../Firebase/index';

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field'),
  password: Yup.string().required('Password is required field'),
});

export const SignInModal = withRouter(props => {
  const { history, location, isOpen, onToggle, onClose } = props;
  const [error, setError] = useState(undefined);
  const firebase = useFirebase();

  const redirectAfterSignup = () => {
    const params = new URLSearchParams(location.search);
    const returnUrl = params.get('returnUrl');

    if (returnUrl) {
      history.push(returnUrl);
    } else {
      history.push(ROUTES.MY_POLICIES);
    }
  };

  const handleSubmit = async ({ email, password }, formikBag) => {
    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      redirectAfterSignup();
    } catch (error) {
      setError('Please check your credentials and try again.');
      console.error(error.message);
    }
    formikBag.setSubmitting(false);
  };

  const handleSignInWithGoogle = async () => {
    await firebase.doSignInWithGoogle();
    redirectAfterSignup();
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} close={onClose}>
        Sign In
      </ModalHeader>
      {error && <Message text={error} type="error" />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <>
          <ModalBody>
            <InputField
              type="email"
              label="Work Email"
              name="email"
              placeholder="Work Email"
              className="custom-input"
            />
            <InputField
              type="password"
              label="Password"
              name="password"
              placeholder="Password"
              className="custom-input"
            />
            <Link className="forgot-password-link" to="/forgot-password">
              Forgot Password? Click here
            </Link>
          </ModalBody>
          <SignInButtons handleSignInWithGoogle={handleSignInWithGoogle} />
        </>
      </Formik>
    </Modal>
  );
});
