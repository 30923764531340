import { FormGroup, Input, Label, InputGroupAddon, Button } from 'reactstrap';
import React from 'react';
import { css, cx } from 'emotion';

import { ProgressSpinner } from './ProgressSpinner';

import { injectFormikField } from '../../utils/inject-formik-field';

const errorStyle = css`
  color: #dc3545;
`;

const buttonStyle = css`
  margin: 20px auto;
  padding: 0 !important;
  width: 114px !important;
  height: 44px !important;
`;

export const InputComponent = props => {
  const {
    name,
    onChange,
    label,
    value,
    type,
    error,
    invalid,
    placeholder,
    onBlur,
    touched,
    addOnLabel,
    handleAddOnClick,
    className,
    containerClassName,
    isSubmitting,
  } = props;

  return (
    <FormGroup className={containerClassName}>
      <Label for={name}>{label}</Label>
      <Input
        type={type}
        name={name}
        id={name}
        value={value}
        invalid={invalid}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        className={className}
      />
      {error && touched && <div className={errorStyle}>{error}</div>}
      {addOnLabel && (
        <InputGroupAddon addonType="append">
          <Button
            className={cx('append-button', buttonStyle)}
            onClick={handleAddOnClick}
          >
            {isSubmitting ? <ProgressSpinner /> : addOnLabel}
          </Button>
        </InputGroupAddon>
      )}
    </FormGroup>
  );
};

export const InputField = injectFormikField(props => {
  const {
    setValue,
    value,
    field: { name },
    label,
    type,
    isInErrorState,
    errorMessage,
    placeholder,
    onBlur,
    touched,
    addOnLabel,
    handleAddOnClick,
    className,
    containerClassName,
    isSubmitting,
  } = props;

  return (
    <InputComponent
      name={name}
      label={label}
      type={type}
      value={value}
      invalid={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onBlur={onBlur}
      touched={touched}
      addOnLabel={addOnLabel}
      handleAddOnClick={handleAddOnClick}
      className={className}
      containerClassName={containerClassName}
      isSubmitting={isSubmitting}
    />
  );
});
