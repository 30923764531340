import React from 'react';

const Footer = () => (
  <footer className="custom-footer page-footer font-small blue">
    <div className="container">
      <div className="row">
        <p className="footer-copyright w-100 text-center mb-0">
          © Insync Insurance Solutions Ltd 2019 All rights reserved. Mobiru is a
          trading style of Insync Insurance Solutions Ltd which is authorised &
          regulated by the Financial Conduct Authority. Our registered office is
          Midland House, 2 Poole Road, Bournemouth, Dorset BH2 5QY and we are
          registered in England under company number 08810662. Should you have
          cause to complain, and you are not satisfied with our response to your
          complaint, you may be able to refer it to the Financial Ombudsman
          Service, which can be contacted as follows: The Financial Ombudsman
          Service Exchange Tower, London, E14 9SR 123 9 123
          <br />
          <a className="color-white" href="tel:08000234567">
            0800 023 4567
          </a>
          &nbsp;|&nbsp;
          <a className="color-white" href="tel:03001239123">
            0300 123 9 123
          </a>
          &nbsp;|&nbsp;
          <a
            className="color-white"
            href="https://www.financial-ombudsman.org.uk/"
          >
            www.financial-ombudsman.org.uk
          </a>
          &nbsp;|&nbsp;
          <a
            className="color-white"
            href="https://insynccentraldocs.blob.core.windows.net/centraldocs/Insync%20TOBA.pdf"
          >
            Terms of Business†
          </a>
          &nbsp;|&nbsp;
          <a
            className="color-white"
            href="https://www.iubenda.com/privacy-policy/67233965"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
