import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { InputField } from '../shared/InputField';
import { Message } from '../shared/Message';

import { useFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const inputStyle = css`
  width: 300px;
`;

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field'),
});

export const ForgotPassword = () => {
  const firebase = useFirebase();
  const [info, setInfo] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [user, userLoading, error] = useAuthState(firebase.auth);

  if (error) {
    console.error(error);
  }

  const handleSubmit = async (values, formikBag) => {
    setInfo(undefined);
    setErrorMessage(undefined);
    try {
      await firebase.doSendPasswordResetEmail(values.email);
      setInfo('Password reset e-mail has been sent.');
    } catch (error) {
      setErrorMessage('Error while sending password reset e-mail.');
      console.error(error.message);
    }
    formikBag.setSubmitting(false);
  };

  if (userLoading) {
    return null;
  }

  if (user) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <>
      {!user && (
        <div className={cx('form-container', containerStyle)}>
          <h2>Forgot Password</h2>
          {info && <Message type="info" text={info} />}
          {errorMessage && <Message type="error" text={errorMessage} />}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formik => (
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                className="fp-input"
                addOnLabel="Submit"
                handleAddOnClick={formik.handleSubmit}
                containerClassName={inputStyle}
                isSubmitting={formik.isSubmitting}
              />
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
