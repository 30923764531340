import React from 'react';
import { Button, Container, Row } from 'reactstrap';
import {css} from "emotion";

const titleStyle = css`
  margin-top: 5%;
  color: #32ADE3!important;
`

const blackText = css`
  color: #404042;
  margin-top: 50px;
`

const linkStyle = css`
  text-decoration: none;
  color: #32ADE3!important;
  font-weight: bold;
  &:hover{
    text-decoration: none;
    color: #61caf9!important;
  }
`

export const HomePageContent = ({ user, onToggleSignIn, onToggleSignUp }) => (
  <Container fluid>
    <Row className="text-left">
      <div className={user && 'text-color-main w-100 mt-4'}>
        <h1 className={titleStyle}>The Perkbox Mobile Phone Scheme is now closed</h1>
        <p className={blackText}>
          The Perkbox Mobile Phone Scheme is now closed, for further
          information please contact your Perkbox Account Manager
          directly on <br/><a className={linkStyle} href={'tel:020 8051 4555'}>020 8051 4555 </a>
          or email <a className={linkStyle} href={'mailto:help-me@perkbox.co.uk'}>help-me@perkbox.co.uk</a>
        </p>
      </div>
    </Row>
  </Container>
);
