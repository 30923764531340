import React from 'react';
import { css } from 'emotion';

const containerStyle = type => css`
  color: ${type === 'error' ? '#dc3545' : '#2ecc71'};
  text-align: center;
  margin-top: 20px;
`;

export const Message = ({ type, text }) => (
  <div className={containerStyle(type)}>{text}</div>
);
