import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import logo from './assets/logo.png';
import { useFirebase } from '../Firebase';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const firebase = useFirebase();

  const [user, , error] = useAuthState(firebase.auth);

  if (error) {
    console.error(error);
  }

  return (
    <header>
      <Navbar className="custom-navbar" expand="md">
        <NavbarBrand className="custom-navbar__logo" href="/">
          <img width="140" height="" src={logo} alt="Insync Logo" />
        </NavbarBrand>
      </Navbar>
    </header>
  );
};

export default Header;
