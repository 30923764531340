import React, { useState } from 'react';
import { Field } from 'formik';

// interface IFormikFieldProps {
//   formikName: string;
// }
//
// export interface IFieldProps {
//   setValue?(value: any): void;
//   value: any;
//   touched?: boolean;
//   isInErrorState?: boolean;
//   errorMessage?: string;
//   field?: {
//     onChange: FormikHandlers['handleChange'];
//   onBlur: FormikHandlers['handleBlur'];
//   value: any;
//   name: string;
// };
//   form?: FormikProps<any>;
//   onFocus?();
//   onBlur?();
//   focused?: boolean;
// }

// export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
// export type Omit2<T, K1 extends keyof T, K2 extends keyof Omit<T, K1>> = Omit<Omit<T, K1>, K2>;

export function injectFormikField(Component) {
  const withSetValue = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [focused, setFocus] = useState(false);

    const { field, form } = props;
    const { name, value } = field;

    const setValue = newValueOrEvent => {
      const newValue = newValueOrEvent.target
        ? newValueOrEvent.target.value
        : newValueOrEvent;
      form.setFieldValue(name, newValue);
      form.setFieldTouched(name);
    };

    const touched = form.touched[name];
    const errorMessage = form.errors[name];
    const isInErrorState = !!touched && !!errorMessage;
    const onFocus = () => setFocus(true);
    const onBlur = e => {
      setFocus(false);
      field.onBlur(e);
    };

    return (
      <Component
        {...props}
        setValue={setValue}
        value={value}
        touched={touched}
        isInErrorState={isInErrorState}
        errorMessage={errorMessage}
        onFocus={onFocus}
        onBlur={onBlur}
        focused={focused}
      />
    );
  };

  return props => {
    const { formikName, ...rest } = props;

    return <Field name={formikName} component={withSetValue} {...rest} />;
  };
}
