import React from 'react';
import { Modal, ModalHeader, Button, ModalBody } from 'reactstrap';
import { css, cx } from 'emotion';
import useFirebase from '../Firebase/useFirebase';

const buttonStyle = css`
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  ) !important;
  border: none !important;
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14) !important;
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
`;

const containerStyle = css`
  & div {
    border-width: 2px !important;
  }
`;

export const MyDocumentsModal = props => {
  const {
    isOpen,
    closeComponent,
    onToggle,
    onPolicyDocumentDownload,
    docId,
    perkboxPolicyReference,
    hasMta,
  } = props;
  const firebase = useFirebase();
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={containerStyle}>
      <ModalHeader toggle={onToggle} close={closeComponent}>
        My Policy - Documents
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column mt-4 mb-4">
          <Button
            className={cx('mb-3', buttonStyle)}
            onClick={() =>
              onPolicyDocumentDownload(
                docId,
                perkboxPolicyReference,
                firebase.doDownloadPolicyWordingDocument
              )
            }
          >
            View Policy Wording
          </Button>
          {hasMta ? (
            <Button
              className={cx('mb-3', buttonStyle)}
              onClick={() =>
                onPolicyDocumentDownload(
                  docId,
                  perkboxPolicyReference,
                  firebase.doDownloadPolicyMTADocument
                )
              }
            >
              View MTA Policy Schedule
            </Button>
          ) : (
            <Button
              className={cx('mb-3', buttonStyle)}
              onClick={() =>
                onPolicyDocumentDownload(
                  docId,
                  perkboxPolicyReference,
                  firebase.doDownloadPolicyScheduleDocument
                )
              }
            >
              View Policy Schedule
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
