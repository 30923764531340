import React, { useState } from 'react';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { css, cx } from 'emotion';

import { InputField } from '../../shared/InputField';
import { ProgressSpinner } from '../../shared/ProgressSpinner';
import { Message } from '../../shared/Message';

import * as ROUTES from '../../../constants/routes';
import { useFirebase } from '../../Firebase/index';
import * as Sentry from '@sentry/browser';

const initialValues = { displayName: '', email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field'),
  password: Yup.string().required('Password is required field'),
});

const buttonStyle = css`
  padding: 0 !important;
  width: 114px !important;
  height: 44px !important;
`;

export const SignUpModal = withRouter(props => {
  const { history, location, isOpen, onToggle, onClose } = props;
  const [error, setError] = useState(undefined);
  const firebase = useFirebase();

  const redirectAfterSignup = () => {
    const params = new URLSearchParams(location.search);
    const returnUrl = params.get('returnUrl');

    if (returnUrl) {
      history.push(returnUrl);
    } else {
      history.push(ROUTES.MY_POLICIES);
    }
  };

  const handleSubmit = async (values, formikBag) => {
    const { email, password } = values;
    setError(undefined);
    try {
      await firebase.doSignUp(email, password, 'perkbox');
      await firebase.doGeneratePostSignupMTA();
      redirectAfterSignup();
    } catch (error) {
      setError('Unable to register with provided data.');
      Sentry.captureException(error);
      console.error(error);
    }
    formikBag.setSubmitting(false);
  };
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} close={onClose}>
        Sign up using the same email address as your policy
      </ModalHeader>
      {error && <Message type="error" text={error} />}
      <div className="form-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => (
            <>
              <InputField
                type="email"
                name="email"
                placeholder="Work Email"
                label="Work Email"
                className="custom-input"
              />
              <InputField
                type="password"
                name="password"
                placeholder="Password"
                label="Password"
                className="custom-input"
              />
              <div className="container">
                <div className="row">
                  <Button
                    className={cx('mb-0 mt-4', buttonStyle)}
                    color="primary"
                    onClick={formik.handleSubmit}
                  >
                    {formik.isSubmitting ? <ProgressSpinner /> : 'Sign Up'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
});
