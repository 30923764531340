import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import Auth from '../Auth';
import { HomePage } from '../Homepage/Homepage';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import Header from '../Header';
import Footer from '../Footer';
import MyPolicies from '../MyPolicies';
import UserProfile from '../UserProfile';
import ClaimPolicy from '../ClaimPolicy';
import PrivateRoute from '../PrivateRoute';

import * as Sentry from '@sentry/browser';

import '../../scss/main.scss';
import * as ROUTES from '../../constants/routes';

console.log(process.env.ENV);
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: 'https://970cd0c734464d969279ca269a7763c9@sentry-insync.coreline.hr/2' });
}

export const App = () => (
  <Router>
    <main className="main d-flex flex-column App">
      <div className="content loading-overlay-relative">
        <Header />
        <Route path={ROUTES.HOME} exact component={HomePage} />
        {/*<Route path={ROUTES.CLAIM_POLICY.PATTERN} component={ClaimPolicy} />*/}
        {/*<PrivateRoute path={ROUTES.MY_POLICIES} component={MyPolicies} />*/}
        {/*<PrivateRoute path={ROUTES.USER_PROFILE} component={UserProfile} />*/}
        {/*<Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />*/}
      </div>
      <Footer />
    </main>
  </Router>
);
