import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useFirebase } from '../Firebase';

import { CloseModalButton } from './components/CloseModalButton';
import { SignInModal } from './components/SignInModal';
import { SignUpModal } from './components/SignUpModal';

import './Homepage.scss';
import BG from './assets/background-image.jpg';
import { HomePageContent } from './components/HomePageContent';

export const HomePage = () => {
  const { auth } = useFirebase();
  const [user, userLoading, error] = useAuthState(auth);

  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const handleToggleSignIn = () => setIsSignInOpen(!isSignInOpen);
  const handleToggleSignUp = () => setIsSignUpOpen(!isSignUpOpen);

  if (error) {
    console.error(error);
  }

  if (userLoading) {
    return null;
  }

  const OuterSectionClassName = user
    ? 'homepage-outer homepage-outer--logged'
    : 'homepage-outer';
  const InnerSectionClassName = user
    ? 'homepage-outer__inner homepage-outer__inner--logged'
    : 'homepage-outer__inner';

  return (
    <section
      className={OuterSectionClassName}
      style={{ backgroundImage: `url(${BG})` }}
    >
      <section className={InnerSectionClassName}>
        <HomePageContent
          user={user}
          onToggleSignIn={handleToggleSignIn}
          onToggleSignUp={handleToggleSignUp}
        />
      </section>
    </section>
  );
};
